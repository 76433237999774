import { ResolveFn } from "@angular/router";
import { Resource } from "../resources/http.service";
import { Configuration } from "../models/configuration";
import { CmsService } from "../resources/cms.service";
import { inject } from "@angular/core";

export const configurationResolver: ResolveFn<Resource<Configuration>> = () => {
  const http = inject(CmsService).getConfigurationRessource()
  return http
}
